<template>
    <div>
        <div class="dh_p2a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M3 파트너 소개</span><br>
                    <span class="dh_p1c">모듈러 건축 비즈니스를 바라보는 새로운 시각 M3는 파트너의 성장을 지원합니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 파트너 혜택 -->
                <div class="py-3 pt-5" style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>파트너 혜택</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>M3는 파트너의 성공적인 비지니스 수행을 위한 다양한 지원 프로그램을 제공하고 있습니다.</strong></span></div>
                    <div>
                        <div class="iw-1 py-2 w-100">
                            <div class="iw-3 d-flex">

                                <!-- 사업지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa1.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">01.</span><span class="fkr-set1 fs-12">사업지원</span></div>
                                        <div class="x_div_center">
                                            <ul>
                                                <li class="fkr-set2 fc-w4 pb-2">공동사업 발굴</li>
                                                <li class="fen-set2 pb-2">M3 Platform Service</li>
                                                <li class="fkr-set2">할인 및 인센티브 지급</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 마케팅지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa2.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">02.</span><span class="fkr-set1 fs-12">마케팅지원</span></div>
                                        <div class="x_div_center">
                                            <ul style="padding-bottom:30px">
                                                <li class="fkr-set2 pb-2">고객 마케팅 지원</li>
                                                <li class="fkr-set2">파트너기관 홍보</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex">

                                <!-- 기술지원신청 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa3.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">03.</span><span class="fkr-set1 fs-12">기술지원신청</span></div>
                                        <div class="x_div_center">
                                            <ul>
                                                <li class="fkr-set2 pb-2">파트너 기술지원</li>
                                                <li class="fkr-set2 pb-2">기술 세미나</li>
                                                <li class="fkr-set2">기술 매뉴얼 에셋 제공</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 교육지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa4.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">04.</span><span class="fkr-set1 fs-12">교육지원</span></div>
                                        <div class="x_div_center">
                                            <ul style="padding-bottom:30px">
                                                <li class="fe-set2 pb-2">M3 Platform 교육</li>
                                                <li class="fkr-set2">Hands-on 교육</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div style="height:80px"></div>

                <!-- 파트너 유형 -->
                <div style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>파트너 유형</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>M3는 BIM과 DfMA 플랫폼을 기반으로 각 업종별 파트너기관와 모듈러 건축을 만들어갑니다.</strong></span></div>
                    <div class="">
                        <div class="iw-1 py-2">
                            <!-- 건축설계사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk1.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>건축설계사</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">모듈러 건축 서비스를 필요로 하는 고객에게 각종 건축물을 모듈러 유닛을 기준으로 계획하고 설계해주는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 모듈러 제작사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk2.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>모듈러 제작사</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">공장에서 정밀 제작된 구조체 모듈에 벽체와 바닥, 천장, 창호, 전기, 설비 등 전체 공정의 70~80%를 시공하고, 현장에서 조립하는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="iw-1 py-2">
                            <!-- 시공사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk3.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>시공사</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">건설사업 관리방법 뿐만 아니라 제조업의 생산관리방법에 대한 폭넓은 지식과 기술을 바탕으로 새로운 건설사업 관리하는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 인테리어사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk4.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>인테리어사</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">실내 인테리어 서비스를 고객의 요구사항과 예산에 맞추어 디자인하고 시공해주는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="iw-1 py-2">
                            <!-- 자재사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk5.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>자재사</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">모듈러 건축에 적합한 다양한 자재를 제공하는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 운송 및 중장비업체 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk6.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>운송 및 중장비업체</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">제작된 모듈러를 공장에서 현장까지 운송하고, 현장에 운송된 모듈러를 정확한 위치에 오차없이 양중해주는 파트너</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div style="height:80px"></div>

                <!-- 파트너 등록절차 -->
                <div>
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>파트너 등록절차</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>M3 파트너기관 여러분 반갑습니다. 파트너기관 등록 절차는 다음과 같습니다.</strong></span></div>
                    <div>
                        <div class="iw-1 py-2 w-100">
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 신청 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr1.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">01.</span><span class="fkr-set1 fs-13">신청</span></div>
                                        <div>
                                            <ul>
                                                <li class="fkr-set2 pb-3">파트너 참여를 신청하시면 귀사가 입력한 이메일 주소로 접수 확인 메일을 보내드립니다.</li>
                                                <li class="fkr-set2">참여 신청 시, 귀사의 영업 비밀이나 기밀사항, 각종 지식재산권 등에 해당하는 내용은 반드시 제외하고 공개 가능한 내용으로만 작성하여 주시기 바랍니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 양사협의 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr2.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">02.</span><span class="fkr-set1 fs-13">양사 협의</span></div>
                                        <div>
                                            <ul class="pb-4">
                                                <li class="fkr-set2 pb-3">신청하신 내용을 기반으로 양사간 파트너십 협력에 대해 협의를 합니다.</li>
                                                <li class="fkr-set2 pb-3">양사간 협의를 위해 공유된 자료는 제휴를 위한 목적으로만 이용됩니다.</li>
                                                <li class="fkr-set2">파트너 제휴를 위한 제반 사항에 대해 상호 협의를 진행 합니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 계약 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr3.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">03.</span><span class="fkr-set1 fs-13">계약</span></div>
                                        <div>
                                            <ul>
                                                <li class="fkr-set2 pb-3">신청하신 내용을 기반으로 양사간 파트너십 협력에 대해 협의를 합니다.</li>
                                                <li class="fkr-set2">계약은 온/오프라인으로 체결합니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 등록 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr4.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">04.</span><span class="fkr-set1 fs-13">등록</span></div>
                                        <div>
                                            <ul class="pb-4">
                                                <li class="fkr-set2 pb-3">계약 체결 후 포털 및 각종 자료에 파트너 소개 컨텐츠가 게재됩니다.</li>
                                                <li class="fkr-set2 pb-3">M3systems에 담당자가 배정되어 기술 및 마케팅을 지원합니다.</li>
                                                <li class="fkr-set2">파트너를 위한 ʻ파트너 포털’사용이 가능합니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

            </div>
        </div>

        <div class="x_div_center" style="background-color:#000137;">

            <div class="rp_set">
                <br>
                <div class="text-center px-2"><span class="fkr-set1 fs-13 fc-w1">M3와 함께 성장하고자하는 여러분의 참여를 기다립니다.</span></div>
                <div class="w-100 py-3 pt-5">
                    <div class="table-responsive" style="background-color: white; border:1px solid #c9c9c9; border-radius:8px">
                        <table class="table-borderless w-100" style="table-layout:fixed">
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" width="110" style="background-color:#efefef"><span class="fkr-set1">회사명*</span></td>
                            <td class="text-center px-2"><input v-model="consult.companyName" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">회사명(영문)*</span></td>
                            <td class="text-center px-2"><input v-model="consult.companyNameEn" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">대표자명*</span></td>
                            <td class="text-center px-2"><input v-model="consult.representativeNm" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">홈페이지</span></td>
                            <td class="text-center px-2"><input v-model="consult.homepage" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">지명원/<br/>회사소개서*</span></td>
                            <td class="text-center px-2" :style="isMobile? '' : 'display:flex; align-items: center;'">
                                <input type="file" id="attch" class="form-control fkr-set2" style="width:230px;" @change="fileHandling($event)">
                                <div style="display:flex; align-items: center;">
                                    <div :style="'width:6px; height:6px; background-color:#e60; border-radius:50%; ' + (isMobile? 'margin-left: 5px; padding:3px;' : 'margin-left: 15px')"></div>
                                    <span :style="'margin: 10px; font-size: ' + (isMobile? 'smaller' : 'small')">첨부파일의 용량이 20MB를 초과할 경우 회사소개자료는 메일로 별도제출해주시기 바랍니다(<a href="mailto:info@m3.co.kr"><u>info@m3.co.kr</u></a>).</span>
                                </div>
                            </td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">파트너 유형</span></td>
                            <td class="px-2">
                                <select class="custom-select" v-model="consult.ctgry" style="width:200px; height:37px;">
                                    <option :value="item.id" v-for="(item, index) in ctgryList" :key="index">{{item.ctgryName}}</option>
                                </select>
                            </td>
                        </tr>
                        
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">담당자명</span></td>
                            <td class="text-center px-2"><input v-model="consult.name" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">직책</span></td>
                            <td class="text-center px-2"><input v-model="consult.role" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1" style="height:55px;">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">담당자 이메일*</span></td>
                            <td class="text-center px-2"><input v-model="consult.email" type="text" class="form-control" style="width:230px"></td>
                        </tr>
                        <tr class="ls_bg1">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">담당자 휴대폰*</span></td>
                            <td class="d-flex px-2 py-2">
                                <div class="x_div_center"><input v-model="phoneHead" type="text" class="form-control text-center" style="width:68px"></div>
                                <div class="x_div_center px-1">-</div>
                                <div class="x_div_center"><input v-model="phoneMid" type="text" class="form-control text-center" style="width:68px"></div>
                                <div class="x_div_center px-1">-</div>
                                <div class="x_div_center"><input v-model="phoneTail" type="text" class="form-control text-center" style="width:68px"></div>
                            </td>
                        </tr>
                        
                        <tr class="py-2">
                            <td class="text-center br_0" style="background-color:#efefef"><span class="fkr-set1">기타문의사항</span></td>
                            <td class="text-center p-2"><textarea v-model="consult.desc" class="form-control" style="height:200px"></textarea></td>
                        </tr>
                        </table>

                    </div>
                    <br>
                    <li class="custom-checkbox"><input type="checkbox" id="agree2" name="agree2" class="custom-control-input" v-model="checkPp" value="true" >
                    <label class="custom-control-label" for="agree2"><span class="fkr-set2" style="color: white">개인정보처리방침에 동의합니다</span> &nbsp; <a class="fkr-set2 fc-w3" href="#privacyPolicy" data-toggle="modal" data-id="1'">상세보기</a></label></li>

                    <div class="x_div_center py-2"><button @click="onSubmit()" class="fkr-set1 px-4 py-2" style="width:160px; padding: 8px 0 8px 0 !important; background-color:white;">파트너기관 등록신청<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    <br>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Api from '@/api'

export default {
    name: 'MenuJoinPartner',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    data() {
        return {
            submitting: false,
            rpsntvPhoneHead: '',
            rpsntvPhoneMid: '',
            rpsntvPhoneTail: '',
            phoneHead: '',
            phoneMid: '',
            phoneTail: '',
            consult: {
                companyName: '',
                companyNameEn: '',
                representativeNm: '',
                representativePhone: '',
                homepage: '',
                name: '',
                role: '',
                phone: '',
                email: '',
                desc: '',
                ctgry: '',
                attchNmList: []
            },
            ctgryList: [],
            multipartFile: null,
            checkPp: false,
            isMobile: false,
            buildMode: process.env.NODE_ENV
        }
    },
    async mounted() {
        this.isMobile = window.innerWidth < 768;
        const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/partners/ctgry/list');
        if(rtnval.result) {
            this.ctgryList = rtnval.data;
            this.consult.ctgry = this.ctgryList[0].id;
        }
    },
    methods: {
        fileHandling(e) {
            this.multipartFile = e.target.files[0];
            this.consult.attchNmList.push(e.target.files[0].name)
        },
        async onSubmit() {
            if(this.validation()) {
                if (confirm("제출하시겠습니까?")) {
                    this.submitting = true;
                    this.consult.representativePhone = this.rpsntvPhoneHead + '-' + this.rpsntvPhoneMid + '-' + this.rpsntvPhoneTail;
                    this.consult.phone = this.phoneHead + '-' + this.phoneMid + '-' + this.phoneTail;
                    const formData = new FormData();
                    if(this.multipartFile) {
                        formData.append("attchmntList", this.multipartFile);
                    }
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(this.consult)], { type: "application/json" })
                    );
                    var rtnval = await Api.post((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/admin/usr/mng/join/partners', formData);
                    if (rtnval.result) {
                        alert("제출되었습니다. 빠른 시일 내에 메일 혹은 전화로 연락드리겠습니다.")
                        this.$router.push('/');
                    }
                    else {
                        alert(rtnval.errormsg);
                        this.multipartFile = null;
                        this.consult.attchNmList = [];
                        window.$('#attch').val('');
                    }
                    this.submitting = false;
                }
            }
        },
        validation() {
            if (!this.consult.companyName || !this.consult.companyNameEn) {
                alert('회사명은 필수 입력사항입니다.');
                return false;
            }
            if (!this.consult.representativeNm){
                alert('대표자명은 필수 입력사항입니다.');
                return false;
            }
            if (!this.phoneHead || !this.phoneMid || !this.phoneTail){
                alert('담당자 휴대폰번호는 필수 입력사항입니다.');
                return false;
            }
            if (!this.consult.email){
                alert('이메일은 필수 입력사항입니다.');
                return false;
            }
            // if (this.consult.attchNmList.length == 0) {
            //     alert('지명원 또는 회사를 소개할 수 있는 자료를 첨부해주세요.');
            //     return false;
            // }
            if(!this.checkPp) {
                alert('이용약관 및 개인정보처리방침은 필수 동의사항입니다.');
                return false;
            }
            return true;
        }        
    }
}
</script>