<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">인재채용</span><br>
                    <span class="dh_p1c">M3의 인재채용 소식을 알려 드립니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- 인재채용 -->
                <div class="py-3 pt-5">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w11"><strong>함께 성장할 New Crew를 찾고 있습니다! </strong></span></div>
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-13 fc-w10"><strong>[모집부문 및 자격요건]</strong></span></div>

                    <!-- 모집부문 -->
                    <div>

                        <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                            <table class="table-borderless w-100" style="table-layout:fixed">
                            <tr style="height:55px;">
                                <td class="text-center br_0" width="200" style="background-color:#4e0000"><span class="fkr-set1 fs-095 fc-w1">모집부문</span></td>
                                <td class="text-center br_0" width="370" style="background-color:#4e0000"><span class="fkr-set1 fs-095 fc-w1">담당업무</span></td>
                                <td class="text-center" width="600" style="background-color:#4e0000"><span class="fkr-set1 fs-095 fc-w1">자격요건 및 우대사항</span></td>
                            </tr>
                            <tr style="border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0">
                                    <span class="fkr-set1 fc-w9">모듈러 생산분야</span><br><br><span class="fkr-set1">신입/경력 채용<br>○ 명</span>
                                </td>
                                <td class="br_0 py-3 pe-2">
                                    <ul style="margin:0">
                                        <li>이천 공장 모듈 생산관리</li>
                                        <li>현장관리</li>
                                    </ul>
                                </td>
                                <td class="py-3">
                                    <ol style="margin:0">
                                        <li><span class="fkr-set1">자격요건 및 우대사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>일반 건축 및 모듈러 건축관련 전공자</li>
                                            <li>CAD 활용 가능자</li>
                                            <li>운전가능자 / 기흥 근무 / 이천 공장 이동 근무 가능자</li>
                                            <li>모듈러 건축 현장 경험자</li>
                                            <li>건축현장 경험자</li>
                                        </ul>
                                        <li class="pt-3"><span class="fkr-set1">특이사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>주 6일 근무</li>
                                        </ul>
                                    </ol>
                                </td>
                            </tr>
                            <tr style="border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0">
                                    <span class="fkr-set1 fc-w9">플랫폼<br>프론트엔드 엔지니어</span><br><br><span class="fkr-set1">신입/경력 채용<br>○ 명</span>
                                </td>
                                <td class="br_0 py-3 pe-2">
                                    <ul style="margin:0">
                                        <li>M3 Systems 플랫폼 웹 프론트엔드 개발</li>
                                        <li>건축물 전체 라이프사이클 지원을 위해 여러 이해관계자들이 활동하는 웹 기반 플랫폼 서비스 유지관리 및 고도화</li>
                                    </ul>
                                </td>
                                <td class="py-3">
                                    <ol style="margin:0">
                                        <li><span class="fkr-set1">자격요건</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>Vue 프레임워크 기반 프론트엔드 서버 개발 경험이 있으신 분</li>
                                            <li>HTML, CSS JavaScript 활용에 능숙하신 분</li>
                                            <li>반응형 웹 디자인에 대한 이해도가 높으신 분</li>
                                            <li>Git, Confluence등의 소스코드, 이슈 관리 소프트웨어를 사용한 적이 있으신 분</li>
                                            <li>스타트업 초기 멤버로 의욕적으로 회사의 문화를 함께 만들어 가실 분</li>
                                        </ul>
                                        <li class="pt-3"><span class="fkr-set1">우대사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>전산 관련 학과 졸업 후 3년 이상 업계 근무 경력이 있으신 분</li>
                                            <li>전통적 기술부터 최신 기술까지 다양한 기술 습득을 목표로 하시는 분</li>
                                            <li>JavaScript를 통한 3D 렌더링 지식이 있으신 분</li>
                                            <li>건축 분야에 지식이 있거나 관심이 있으신 분</li>
                                        </ul>
                                    </ol>
                                </td>
                            </tr>
                            <tr style="border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0">
                                    <span class="fkr-set1 fc-w9">플랫폼<br>백엔드 엔지니어</span><br><br><span class="fkr-set1">신입/경력 채용<br>○ 명</span>
                                </td>
                                <td class="br_0 py-3 pe-2">
                                    <ul style="margin:0">
                                        <li>M3 Systems 플랫폼 백엔드 개발</li>
                                        <li>건축물 전체 라이프사이클 지원을 위해 여러 이해관계자들이 활동하는 웹 기반 플랫폼 서비스 유지관리 및 고도화</li>
                                    </ul>
                                </td>
                                <td class="py-3">
                                    <ol style="margin:0">
                                        <li><span class="fkr-set1">자격요건</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>백엔드 서버 개발 경험이 있으신 분</li>
                                            <li>Spring Boot 사용에 능숙하신 분</li>
                                            <li>RDB(PostgreSQL) 기반의 DBMS 활용이 가능하신 분</li>
                                            <li>Git, Jira등의 소스코드, 이슈 관리 소프트웨어를 사용한 적이 있으신 분</li>
                                            <li>스타트업 초기 멤버로 의욕적으로 회사의 문화를 함께 만들어 가실 분</li>
                                        </ul>
                                        <li class="pt-3"><span class="fkr-set1">우대사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>전산 관련 학과 졸업 후 3년 이상 업계 근무 경력이 있으신 분</li>
                                            <li>전통적 기술부터 최신 기술까지 다양한 기술 습득을 목표로 하시는 분</li>
                                            <li>AWS와 같은 클라우드 기반 서버 플랫폼에 대한 지식이 있으신 분</li>
                                            <li>건축 분야에 지식이 있거나 관심이 있으신 분</li>
                                        </ul>
                                    </ol>
                                </td>
                            </tr>
                            <tr style="border-bottom:1px solid #c9c9c9">
                                <td class="text-center br_0">
                                    <span class="fkr-set1 fc-w9">공무 분야</span><br><br><span class="fkr-set1">경력 채용<br>○ 명</span>
                                </td>
                                <td class="br_0 py-3 pe-2">
                                    <ul style="margin:0">
                                        <li>프로젝트 별 실행 원가 관리</li>
                                        <li>각종 사무 문서 관리</li>
                                        <li>견적서 작성</li>
                                        <li>CAD 활용</li>
                                        <li>건축제조사업본부 사무 업무 지원</li>
                                        <li>고객 관리</li>
                                        <li>필요시 현장 확인</li>
                                    </ul>
                                </td>
                                <td class="py-3">
                                    <ol style="margin:0">
                                        <li><span class="fkr-set1">자격요건</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>경력 3년 이상</li>
                                            <li>학력 무관</li>
                                            <li>MS Office(엑셀 및 파워포인트 등 사무자동화 프로그램 활용 능숙)</li>
                                        </ul>
                                        <li class="pt-3"><span class="fkr-set1">우대사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>건설사 공무 경력 3년 이상 경험자</li>
                                            <li>컴퓨터 활용능력 우수</li>
                                            <li>커뮤니케이션 및 협업 능력 우수</li>
                                            <li>공무, 경리 경험 우대</li>
                                            <li>CAD 활용 가능자</li>
                                            <li>운전 가능자</li>
                                        </ul>
                                    </ol>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center br_0">
                                    <span class="fkr-set1 fc-w9">마케팅/홍보 분야</span><br><br><span class="fkr-set1">경력 채용<br>○ 명</span>
                                </td>
                                <td class="br_0 py-3 pe-2">
                                    <ul style="margin:0">
                                        <li>마케팅 홍보</li>
                                        <li>M3 Systems 기술연구소 업무지원</li>
                                    </ul>
                                </td>
                                <td class="py-3">
                                    <ol style="margin:0">
                                        <li><span class="fkr-set1">자격요건</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>학사이상</li>
                                            <li>마케팅 홍보 전반적 업무 가능자(유튜브, 인스타그램 등 SNS 홍보 및 동영상 편집 가능자)</li>
                                            <li>제조화건설 스타트업 초기 멤버로 의욕적으로 회사의 문화를 함께 만들어 가실 분</li>
                                        </ul>
                                        <li class="pt-3"><span class="fkr-set1">우대사항</span></li>
                                        <ul style="margin:0 0 0 -12px">
                                            <li>관련학과 졸업 및 유경험자</li>
                                            <li>건축 분야에 지식이 있거나 관심이 있으신 분</li>
                                            <li>미래 건설에 대한 열정이 높으신 분</li>
                                        </ul>
                                    </ol>
                                </td>
                            </tr>
                            </table>
                        </div>
                    </div>

                    <!-- [혜택 및 복지] / [근무조건] -->
                    <div>
                        <div class="iw-1 py-2 w-100" style="border-bottom:1px solid #e1e1e1">
                            <div class="iw-3 d-flex">

                                <!-- 혜택 및 복지 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[혜택 및 복지]</strong></span></div>
                                        <div>
                                            <ol style="margin:0">
                                                <li><span class="fkr-set1 fs-10">자유롭고 창의적인 회사</span></li>
                                                <ul class="pb-3" style="margin:0 0 0 -12px">
                                                    <li class="fkr-set2">자율복장</li>
                                                    <li class="fkr-set2">자율휴가제도</li>
                                                </ul>

                                                <li><span class="fkr-set1 fs-10">일과 삶의 균형</span></li>
                                                <ul class="pb-3" style="margin:0 0 0 -12px">
                                                    <li class="fkr-set2">업무에 필요한 교육, 세미나, 컨퍼런스, 도서구매 비용 지원</li>
                                                </ul>

                                                <li><span class="fkr-set1 fs-10">자유롭고 창의적인 회사</span></li>
                                                <ul class="pb-3" style="margin:0 0 0 -12px">
                                                    <li class="fkr-set2">메인 오피스(기흥), 공장(이천)</li>
                                                </ul>

                                                <li><span class="fkr-set1 fs-10">청년내일채움공제</span></li>
                                                <ul class="pb-3" style="margin:0 0 0 -12px">
                                                    <li class="fkr-set2">업무에 필요한 교육, 세미나, 컨퍼런스, 도서구매 비용 지원</li>
                                                </ul>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 근무조건 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[근무조건]</strong></span></div>
                                        <div>
                                            <ul class="pb-3" style="margin:0 0 0 -12px">
                                                <li class="fkr-set2 py-2">채용형태 : 수습기간 3개월 후 정규직 전환(경력에 따라 협의 가능) </li>
                                                <li class="fkr-set2 py-2">급여 : 인센티브제, 4대 보험, 회사내규(면접 후 결정)</li>
                                                <li class="fkr-set2 py-2">근무시간 : 주 5일(월 ~ 금) 08:00 ~ 17:00 (단, 개발부서는 자율)</li>
                                                <li class="fkr-set2 py-2">근무지 : 경기도 용인시 기흥구 신갈동</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <!-- [전형절차] / [접수기간 및 방법] -->
                    <div>
                        <div class="iw-1 py-2 w-100" style="border-bottom:1px solid #e1e1e1">
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 전형절차 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[전형절차]</strong></span></div>
                                        <div><img src="@/assets/img/s_info/s_info6_1.png" width="100%">

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 접수기간 및 방법 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[접수기간 및 방법]</strong></span></div>
                                        <div>
                                            <ul class="pb-3" style="margin:0 0 0 -12px">
                                                <li class="fkr-set2 py-2">접수기간 : 수시채용</li>
                                                <li class="fkr-set2 py-2">접수방법 : 제출서류 작성 후 이메일 제출(recruit@m3.co.kr)</li>
                                                <li class="fkr-set2 py-2">제출서류 : <a href="https://s3.ap-northeast-2.amazonaws.com/m3.company.com/platform/download/M3%EC%8B%9C%EC%8A%A4%ED%85%9C%EC%A6%88_%EC%9D%B4%EB%A0%A5%EC%84%9C%EB%B0%8F%EC%9E%90%EA%B8%B0%EC%86%8C%EA%B0%9C%EC%84%9C_%EC%96%91%EC%8B%9D.hwp" target="_blank"><b><u>지원 양식 다운로드</u></b></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <!-- [기타사항] / [유의사항] -->
                    <div>
                        <div class="iw-1 py-2 w-100" style="border-bottom:1px solid #e1e1e1">
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 전형절차 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[기타사항]</strong></span></div>
                                        <div>
                                            <ul class="pb-3" style="margin:0 0 0 -12px">
                                                <li class="fkr-set2">문의처 : 인사담당자 / 1660-0133 (<a href="mailto:ops@m3.co.kr">recruit@m3.co.kr</a>)</li>
                                                <li class="fkr-set2">홈페이지 : https://m3.co.kr</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 유의사항 -->
                                <div class="w-100">
                                    <div class="p-4">
                                        <div class="py-3"><span class="fkr-set2 fs-13 fc-w10"><strong>[유의사항]</strong></span></div>
                                        <div>
                                            <ul class="pb-3" style="margin:0 0 0 -12px">
                                                <li class="fkr-set2 py-2">입사지원 서류에 허위사실이 발견될 경우, 채용확정 이후라도 채용이 취소될 수 있습니다.</li>
                                                <li class="fkr-set2 py-2">보훈대상자 및 장애인은 관련 법률에 의거하여 우대합니다.</li>
                                                <li class="fkr-set2 py-2">해외여행에 결격사유가 있는 경우 지원이 불가합니다.</li>
                                                <li class="fkr-set2 py-2">제출된 서류는 일체 반환하지 않습니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
export default {
    name: 'MenuCareer',
    components: {
        MenuFooter
    }
}
</script>