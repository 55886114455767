<template>
  <div>
    <div class="pg_m_set" style="margin: 150px 0px 150px 0px" v-if="loading">
      <div class="x_div_center"><h3><i class='fa fa-spinner fa-spin'></i></h3></div>
    </div>
    <div v-else>
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
<!--        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>-->
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="mh_p1 bi1"></div>
          <a href="/menu/portfolio/shingaloffice">
            <div class="carousel-caption d-md-block">
              <div class="mh_p1a" style="">
                <div class="text-start">
                  <p class="fks-set1 fs-15 fc-w8"><strong>Fast but Reliable</strong></p>
                  <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>프리미엄 모듈러</strong></p-->
                  <p class="mh_p1b">프리미엄 모듈러 건축</p>
                  <p class="mh_p1g" style="color:#a6c1d8 !important;">당신의 생각이 작품이 됩니다.</p>
                  <div style="display:flex">
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(78, 0, 0, 0.3); position:relative; margin-right: 15px;">
                      <a href="https://buildmarket.kr/plan" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">기획하기 ></strong>
                        </div>
                      </a>
                    </div>
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(0, 78, 0, 0.3); position:relative">
                      <a href="https://buildmarket.kr/mhouse/model" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fas fa-shopping-basket"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">골라보기 ></strong>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="carousel-item">
        <div class="mh_p1 bi2"></div>
        <a href="/menu/portfolio/prj01">
          <div class="carousel-caption d-md-block">
            <div class="mh_p1a text-start">
              <p class="fks-set1 fs-15 fc-w8"><strong>State-of-the-art-technology</strong></p>
              <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>사람중심 미래건축</strong></p-->
              <p class="mh_p1b">사람중심 미래건축</p>
              <p class="mh_p1c">M3가 건축 패러다임을 앞당깁니다.</p>
              <div style="display:flex">
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(78, 0, 0, 0.3); position:relative; margin-right: 15px;">
                      <a href="https://buildmarket.kr/plan" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">기획하기 ></strong>
                        </div>
                      </a>
                    </div>
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(0, 78, 0, 0.3); position:relative">
                      <a href="https://buildmarket.kr/mhouse/model" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fas fa-shopping-basket"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">골라보기 ></strong>
                        </div>
                      </a>
                    </div>
                  </div>
            </div>
          </div>
        </a>
        </div>
        <div class="carousel-item">
          <div class="mh_p1 bi3"></div>
          <a href="/menu/portfolio/mhouse">
            <div class="carousel-caption d-md-block">
              <div class="mh_p1a text-start">
                <p class="fks-set1 fs-15 fc-w6"><strong>Best Time-to-Market</strong></p>
                <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>M3 원스톱 서비스와 독보적인 기술로</strong></p-->
                <p class="mh_p1g">M3 원스톱 서비스와 독보적인 기술로</p>
                <p class="mh_p1f">보다 빠른 투자회수 및 사업을<br/>시작하도록 도와드립니다.</p>
                <div style="display:flex">
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(78, 0, 0, 0.3); position:relative; margin-right: 15px;">
                      <a href="https://buildmarket.kr/plan" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">기획하기 ></strong>
                        </div>
                      </a>
                    </div>
                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(0, 78, 0, 0.3); position:relative">
                      <a href="https://buildmarket.kr/mhouse/model" target="_blank" style="color: #fff">
                        <div style="display:flex">
                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fas fa-shopping-basket"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">골라보기 ></strong>
                        </div>
                      </a>
                    </div>
                  </div>
              </div>
            </div>
          </a>
        </div>
<!--        <div class="carousel-item">-->
<!--        <div class="mh_p1 bi4"></div>-->
<!--        <a href="/menu/portfolio/mstudio">-->
<!--          <div class="carousel-caption d-md-block">-->
<!--            <div class="mh_p1a text-start">-->
<!--              <p class="fks-set1 fs-15 fc-w8"><strong>State-of-the-art-technology</strong></p>-->
<!--              &lt;!&ndash;p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>사람중심 미래건축</strong></p&ndash;&gt;-->
<!--              <p class="mh_p1d">사람중심 미래건축</p>-->
<!--              <p class="mh_p1d">M3가 건축 패러다임을 앞당깁니다.</p>-->
<!--              <div style="display:flex">-->
<!--                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(78, 0, 0, 0.3); position:relative; margin-right: 15px;">-->
<!--                      <a href="https://buildmarket.kr/plan" target="_blank" style="color: #fff">-->
<!--                        <div style="display:flex">-->
<!--                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">기획하기 ></strong>-->
<!--                        </div>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                    <div class="box-type-2 p-2 px-3 plan-shortcut" style="z-index:3; background-color: rgba(0, 78, 0, 0.3); position:relative">-->
<!--                      <a href="https://buildmarket.kr/mhouse/model" target="_blank" style="color: #fff">-->
<!--                        <div style="display:flex">-->
<!--                          <h1 class="plan-shortcut-icon" style="margin: 15px"><i class="fas fa-shopping-basket"></i></h1><strong class="plan-shortcut-text" style="position:absolute; bottom: 40%;">골라보기 ></strong>-->
<!--                        </div>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--        </div>-->
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

        <div class="x_div_center">
            <div class="rp_set">
                <!-- FAQ -->
                <div class="w-100 py-3 pt-5">
                    <div class="py-3">
                        <div style="border-top:1px solid #e7e7e7">
                            <div v-for="(faq, index) in faqList" :key="index">
                                <a :href="'#mfaq'+index" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'mfaq'+index">
                                    <div class="p-3 ls_bg1"><span class="fkr-set1">{{faq.sbjct}}</span></div>
                                </a>
                                <div :id="'mfaq'+index" class="collapse p-4" style="background-color:#f2f2f2; border-bottom:1px solid #e1e1e1">
                                    <span class="fkr-set2" v-html="faq.cntnt"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="x_div_end py-2"><a href="/menu/faq"><button class="fkr-set1 px-4 py-2 fc-w1" style="width:120px; background-color:#4e0000; border:1px solid #e9e9e9; border-radius:4px">더보기</button></a></div>-->
            </div>
        </div>

    <div class="x_div_center">
      <div class="rp_set" style="border:0px solid red">

        <!-- M3 주요 서비스 -->
        <div class="py-3 pt-5">
          <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>M3 주요 서비스</strong></span></div>
          <div class="text-left px-3 py-3" style="padding-left: 0rem !important;"><span class="fkr-set1 fs-13">품질 높은 프리미엄 건축물을 빠르게 제공합니다.</span></div>
          <div class="iw-1 py-2 w-100">
            <div class="iw-3 d-flex" style="align-items:start;">

              <!-- 특화 알고리즘, 나만의 디자인 -->
              <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:350px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma1-1.png" width="100%"></div>
                  <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">특화 알고리즘, 나만의 디자인</span></div>
                  <div class="text-center"><span class="fkr-set2 fc-w12">모듈러 조합 알고리즘을 활용하여 건축주 요구에 대응하는 건축 모델을 제안합니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <!-- 전문가와 함께하는 설계 -->
              <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:350px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma1-2.png" width="100%"></div>
                  <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">전문가와 함께하는 설계</span></div>
                  <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">전문적인 지원을 통해 설계에 필요한 노력과 시간을 절감해드립니다.</span></div>
                </div>
              </div>

            </div>
            <div class="p-2"></div>
            <div class="iw-3 d-flex" style="align-items:start;">

              <!-- 빠르고 정확한 모듈러 시공 -->
              <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:350px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma1-3.png" width="100%"></div>
                  <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">빠르고 정확한 모듈러 시공</span></div>
                  <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">설계가 완성되면 안전하고 합리적인 비용으로 공사를 진행해드립니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <!-- 유지보수 지원 -->
              <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:350px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma1-4.png" width="100%"></div>
                  <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">유지보수 지원</span></div>
                  <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">공사가 완료된 이후에도, 불편함 없도록 유지보수 지원해 드립니다.</span></div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

    <div id="v2" class="x_div_center py-4" style="background-color:#000137">

      <!-- M3 소개영상 -->
      <!-- <div class="rp_set">
        <div class="iw-1 py-2 w-100 align-items-center">
          <div class="iw-3 align-items-center">
            <div class="p-3">
              <div>
                <div>
                <div class="py-3 text-left"><span class="fkr-set2 fs-13 fc-w1"><strong>M3 소개영상</strong></span></div>
                <div class="py-3 text-left"><span class="fs-20 fc-w1"><strong>AI기반 다양한 모듈 평면 조합으로,<br/>고객 맞춤형 디자인 추천</strong></span></div>
                  <div class="py-3 text-left"><span class="fs-10 fc-w1">M3는 수많은 설계 평면을 분석하여 국내 요구에 최적화된 단위 공간 모듈을 지속적으로 개발하고 있습니다.  자체 플랫폼에서 이를 체계적으로 조합할 수 있는 첨단 디지털 설계 기법을 통하여 다양한 사용자의 요구 사항에 꼭 맞는 디자인을 제공합니다.</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="iw-3 align-items-center">
            <div class="p-2">
              <div>

                <iframe class="_img" id="img_w2022070500e900bfa62e1" src="https://www.youtube.com/embed/?loop=0&amp;rel=0&amp;playlist=ZA5bvTkEbgA&amp;showinfo=1&amp;controls=0&rel=0&amp;mute=0&amp;autoplay=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fm3systems.co.kr&amp;widgetid=1" style="display: block;  margin: 0px;" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="M3systems, 당신의 생각이 작품이 됩니다." width="100%" height="400" sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox" data-gtm-yt-inspected-4="true"></iframe>

              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="rp_set">
        <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w1"><strong>M3 소개영상</strong></span></div>
        <div class="iw-1 py-2 w-100 align-items-center" v-for="(bucket, index) in videoSlot" :key="index">
          <div class="iw-3 align-items-center" v-for="(item, kintex) in bucket" :key="kintex">
            <div class="p-2">
              <div>
                <iframe class="_img" id="img_w2022070500e900bfa62e1" :src="'https://www.youtube.com/embed/?loop=0&amp;rel=0&amp;playlist=' + item + '&amp;showinfo=1&amp;controls=0&rel=0&amp;mute=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fm3systems.co.kr&amp;widgetid=1'" style="display: block;  margin: 0px;" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="M3systems, 당신의 생각이 작품이 됩니다." width="100%" height="400" sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox" data-gtm-yt-inspected-4="true"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="x_div_center">
          <a href="javascript:void(0)" @click="moveNav(0)" class="px-2" v-if="isMobileDevice">
              <img src="@/assets/img/s_arrow_l.png" width="50px" height="50px">
          </a>
          <a href="https://www.youtube.com/channel/UCM6JVuH0hof0OJFq1ivrucA" target="_blank" style="margin: 15px"><span class="fkr-set2 fs-13 fc-w1"><strong>더 보기</strong></span></a>
          <a href="javascript:void(0)" @click="moveNav(1)" class="px-2" v-if="isMobileDevice">
              <img src="@/assets/img/s_arrow_r.png" width="50px" height="50px">
          </a>
        </div>
      </div>

    </div>


    <div class="x_div_center">
      <div class="rp_set" style="border:0px solid red">

        <!-- M3 모듈러 건축 특장점 -->
        <div class="py-3" style="border-bottom:1px solid #e1e1e1">
          <div class="x_div_left py-3"><span class="fkr-set2 fs-16 fc-w10"><strong>M3 모듈러 건축 특장점</strong></span></div>
          <div>
            <div class="iw-1 py-2 w-100">
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-1.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">높은 완성도</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">공장 실내 환경에서 엄격한 품질관리를 통해 규격화된 자재로 생산하여 완성도가 높습니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-2.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">건축비 절감</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">신속한 설계와 자동화된 제작으로 설계 세부적인 절차와 인건비를 줄여줍니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-3.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">공사기간 단축</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">공장 제작된 모듈은 현장에서 신속하게 조립되어 공사 기간을 획기적으로 줄입니다.</span></div>
                </div>
              </div>
            </div>
            <div class="iw-1 py-2 w-100">
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-4.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">나만의 디자인</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">다양한 설계가 가능하며 초기단계에서 건축주가 손쉽게 조정하여 디자인을 완성할 수 있습니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-5.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">유지비 절감</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">자재의 에너지 등급이 우수하며, 모듈간 열차단 효과로 냉난방 유지 비용을 절감할 수 있습니다.</span></div>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
                <div class="p-4">
                  <div class="x_div_center"><img src="@/assets/img/main/ma3-6.png" height="120"></div>
                  <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">이동 가능</span></div>
                  <div class="text-center"><span class="fs-09 fc-w0">조립된 건축물은 모듈 단위로 해체가 가능하여 장소 이동과 확장이 가능합니다.</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 고객 사례 -->
<!--        <div class="py-3">
          <div class="x_div_left py-3"><span class="fkr-set2 fs-16 fc-w10"><strong>고객 사례</strong></span></div>

          <div class="">

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="slider-item swiper-slide">
                  <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-1.png" alt="SliderImg"></div>
                  <div class="slider-item-content">
                    <div class="px-4 pt-3"><span class="fkr-set1 fs-20 fc-w0">호근동 근린생활시설</span></div>
                    <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX 시리즈 A - "바다를 담다"</span></div>
                    <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3Systems 의 DfMA 모듈러 건축기술을 적용하여, 공사기간을 단축하고 시공비를 최소화한 ModuleX Commercial, Series A 를 소개합니다.<br>바다와 하늘 그리고 날씨의 변화를 두 개의 액자에 담는 컨셉으로 디자인했습니다.<br>사무동과 카페동의 사이를 오픈하여 뒤에 있는 건물을 배려하고, 오픈 계단으로 루프탑 공간으로 동선을 연결시켜, 눈앞에 펼쳐지는 바다 조망을 품고, 주변 컨텍스트와 소통하는 공간을 제공합니다.</span></div>
                  </div>
                </div>
                <div class="slider-item swiper-slide">
                  <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-2.png" alt="SliderImg"></div>
                  <div class="slider-item-content">
                    <div class="px-4 pt-3"><span class="fkr-set1 fs-20 fc-w0">경기 신갈동 근린생활시설</span></div>
                    <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX 시리즈 B - "Out of the Box"</span></div>
                    <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3Systems 의 DfMA 모듈러 건축기술을 적용하여 디자인한 ModuleX Commercial, Series B 를 소개합니다.<br>전면부의 곡선 비정형으로 파사드로 유니크한 개성을 살렸고, 개방형 공용 공간 디자인으로, 협업과 창의력, 업무 유연성, 그리고 혁신적인 아이디어를 창출할 수 있는 사무공간을 제공합니다.<br>옥상층의 바이로필릭 디자인 (Biophililc Desugb) 은 자연이 제공하는 퓽요로움을 통해 사용자들에게 재충전과 여유를 찾을 수 있는 공간을 제공합니다.</span></div>
                  </div>
                </div>
                <div class="slider-item swiper-slide">
                  <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-3.png" alt="SliderImg"></div>
                  <div class="slider-item-content">
                    <div class="px-4 pt-3"><span class="fkr-set1 fs-20 fc-w0">충남 서산 단독주택</span></div>
                    <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX 시리즈 H - "추억 저장소"</span></div>
                    <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">DfMA 기반 2가지 타입의 단독주택으로 1호 주택과 2호 주택으로 구성되어 있습니다.<br>1호 주택은 확트인 천정고로 채광이 풍부하고, 개개인 라이프스타일을 존중할 수 있는 짜임새 있는 실내 평면으로, 독립된 방 3개를 제공합니다.<br>드레스룸과 수납장등이 구비되어 편의성을 고려하여 설계되었습니다.<br>2호 주택은 재충전과 여유를 한껏 누릴 수 있도록 녹지공간으로 확트인 거실과 다이닝룸을 제공합니다.<br>1.5층 공간에 다양한 목적으로 활용하실 수 있는 알파룸을 제공합니다.</span></div>
                  </div>
                </div>
              </div>

              <div style="height:40px"></div>

              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>-->

        <MenuFooter/>

        <div id="announcePop" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <img src="@/assets/img/popup_logo.png" style="float:right; width: 30%">
                <br><br/><br/>
                <div class="text">
                  <span class="fw-bold" style="font-size: large;">엠쓰리시스템즈 사이트 개편 공지</span>
                  <br><br/>
                  M3 Systems를 이용해주시는 고객님께 항상 감사의 인사를 전해드립니다.<br/>
                  고객님께서 이용하시던 <b>기획하기 및 회원 서비스</b>가 보다 나은 사용자경험을 위해 M3모듈러 브랜드사이트 빌드마켓으로 통합 이전{{isIntegrationOver? '되었습니다.' : '될 예정임을 알려드립니다.'}}<br/>
                  <br/>
                  <div v-if="isIntegrationOver" style="text-align:center !important">
                      <a href="https://buildmarket.kr" target="_self" style="color:deepskyblue"><u><b>[빌드마켓 바로가기]</b></u></a>
                  </div>
                  <div v-else>
                      • 통합일시 : 2024년 <b>3월 12일(화요일) 오전 8시</b>
                  </div>
                  <br/>
                  <span style="color:#4e0000">앞으로 기획하기 및 회원 서비스는 <a href="http://buildmarket.kr" target="_blank">빌드마켓(<u>buildmarket.kr</u>)</a>에서 제공되며, <br v-if="!isMobileDevice">M3 홈페이지 홈 화면에서 바로가기를 통해 손쉽게 방문가능합니다. <br v-if="!isMobileDevice">기존 데이터를 옮기기위해 <b>고객님께서 별도로 취하실 조치는 전혀 없습니다.</b></span>
                  <br><br/>
                  작업 후 1~2일가량 다음 서비스들의 이용에 제약이 있을 수 있습니다.
                  <br v-if="isMobileDevice"/><br/>
                  • M3 모바일 앱<br v-if="isMobileDevice"/>(인터넷 브라우저를 이용한 접속은 곧바로 가능)<br>
                  • M3 카카오톡 알림<br>
                  <br><br/>
                  제약사항으로 인한 불편에 미리 양해를 부탁드립니다.<br/>
                  감사합니다.
                  <br><br/>
                </div>
              </div>
              <div class="modal-footer" style="background-color:#4e0000">
                <input type="checkbox" name="oneDayCheck" id="oneDayCheck" v-model="oneDayCheck" style="display: block"><label for="oneDayCheck" style="color: #fff">하루동안 보지 않기</label>
                <a href="javascript:void(0)" @click="closeModal()" style="color: #fff" data-dismiss="modal">&times;</a>
              </div>
            </div>
          </div>
        </div>

        <div id="eventPop" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <img src="@/assets/img/popup_logo.png" style="float:right; width: 30%">
                <br><br/><br/>
                <div class="text-center event-body" style="height:350px; padding-top: 100px">
                  <span class="fw-bold" style="color:#4e0000; font-size: large;">M3 구독/팔로우 이벤트<br/>참여에 감사드립니다!</span>
                  <br><br/>
                  고객님의 성원에 최고의 제품과<br/>
                  최선의 서비스로 보답하는<br/>
                  모듈러 건축 전문 기업<br/>
                  M3시스템즈가 될 것을 약속드립니다.
                  <br><br/>
                  <span class="fkr-set2 fs-10">
                    <b>아래 입력란에 휴대폰 번호를 입력 후<br><span style="color:#4e0000;">커피쿠폰 받기</span> 버튼을 눌러주세요!</b>
                  </span>
                  <br><br/>
                </div>
              </div>
              <div style="background-color:#4e0000">
                <div class="x_div_center py-4">
                  <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneHead" id="eventPhoneHead" v-model="eventPhoneHeadInput" style="display: block; width:100px" disabled><span style="color:#fff; padding:10px">-</span>
                  <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneMid" id="eventPhoneMid" v-model="eventPhoneMidInput" style="display: block; width:100px"><span style="color:#fff; padding:10px">-</span>
                  <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneTail" id="eventPhoneTail" v-model="eventPhoneTailInput" style="display: block; width:100px">
                </div>
                <div class="x_div_center py-4">
                  <div class="px-2"><button class="x_div_center" @click="closeEvent()" style="width:120px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fkr-set1">커피쿠폰 받기</span></button></div>
                  <div class="px-2"><button class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border:1px solid #fff; border-radius:4px; color:#fff" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// import Auth from '@/api/auth'
import Api from '@/api'
import MenuFooter from '@/components/MenuFooter.vue'
import { mapMutations } from "vuex";

export default {
  name: 'HomePage',
  props: {
    lang: {
        type: String,
        default: 'ko'
    },
    isMobileDevice: {
        type: Boolean,
        default: false
    }
  },
  components: {
    MenuFooter
  },
  data() {
    return {
      loading: true,
      oneDayCheck: false,
      eventPhoneHeadInput: '010',
      eventPhoneMidInput: '',
      eventPhoneTailInput: '',
      videoList: [],
      videoSlot: [],
      videoSlotIndex: 0,
      youtubeApiKey: process.env.VUE_APP_YOUTUBE_APIKEY,
      youtubeChannelId: process.env.VUE_APP_YOUTUBE_CHANNELID,
      ids: [],
      faqList: [],
      isIntegrationOver: false
    }
  },
  watch: {
    lang(val) {
      if(this.$route.query.mode != 'privacy' && val == 'en') {
        var moveTo = '/home'
        if(this.$route.query.event) {
          moveTo += '?event=' + this.$route.query.event;
        }
        location.href = moveTo;
      }
    // },
    //   tap(val) {
    //       this.fetchFaqListById(val);
      }
  },
  created() {

    // var naverLogin = new window.naver.LoginWithNaverId(
	// 		{
	// 			clientId: process.env.VUE_APP_NAVER_OAUTH_CLIENT_ID,
	// 			callbackUrl: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
	// 			isPopup: true,
	// 			callbackHandle: true
	// 		}
	// 	);
    //
	// 	naverLogin.init();

    //const urlstr = window.location.href;
    //const url = new URL(urlstr);
    //const urlParams = url.searchParams;
    // if(urlParams.has('code')){
    //   var rtnval = await Api.post('/api/v1/user/kakao/oauth/token', {code: urlParams.get('code')});
    //   if(rtnval.result){
    //     var rtnValue = await Api.post('/api/v1/user/kakao/oauth/user/me', {access_token: rtnval.data.access_token});
    //       var phone = rtnValue.data.kakao_account.phone_number? rtnValue.data.kakao_account.phone_number.replace('+82 ','0').replace('-','').replace('-','') : '';
    //       var returnValue = await Api.post('/api/v1/user/kakao/oauth/login', {email: rtnValue.data.kakao_account.email, phone: phone, nickname: rtnValue.data.kakao_account.profile.nickname});
    //       if(returnValue.result){
    //         this.setToken(returnValue.data.token);
    //         const returval = await Api.get('/api/v1/user/find/usr/info');
    //         if(returval.result){
    //             this.setUserInfo({id: returnValue.data.id, info: returval.data});
    //             if(this.isMobileDevice) {
    //               location.href = '/'
    //             }
    //             else {
    //               window.close()
    //             }
    //         }
    //       }
    //       else {
    //         alert(returnValue.errormsg);
    //         if(this.isMobileDevice) {
    //           location.href = '/member/login'
    //         }
    //         else {
    //           window.close()
    //         }
    //       }
    //   }
    // }
    // else if(urlstr.indexOf('access_token') >= 0){
    //   naverLogin.getLoginStatus(async (status) => {
    //     if(status){
    //       var email = naverLogin.user.getEmail();
    //       var name = naverLogin.user.getName();
    //       var mobile = naverLogin.user.getMobile();
    //
    //       var returnVal = await Api.post('/api/v1/user/naver/oauth/login', {email: email, phone: mobile.replace('-','').replace('-',''), nickname: name});
    //       if(returnVal.result){
    //         this.setToken(returnVal.data.token);
    //         const returval = await Api.get('/api/v1/user/find/usr/info');
    //         if(returval.result){
    //             this.setUserInfo({id: returnVal.data.id, info: returval.data});
    //             if(this.isMobileDevice) {
    //               location.href = '/'
    //             }
    //             else {
    //               window.close()
    //             }
    //         }
    //       }
    //       else {
    //         naverLogin.logout();
    //         alert(returnVal.errormsg);
    //         if(this.isMobileDevice) {
    //           location.href = '/member/login'
    //         }
    //         else {
    //           window.close()
    //         }
    //       }
    //     }
    //   });
    // }
    // else {
      if(this.$route.query.mode != 'privacy' && this.lang == 'en') {
        var moveTo = '/home'
        if(this.$route.query.event) {
          moveTo += '?event=' + this.$route.query.event;
        }
        location.href = moveTo;
      }
      this.loading = false;
    // }
  },
  async mounted() {
    // eslint-disable-next-line
    var swiper = new window.Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 20,
      effect: 'fade',
      loop: true,
      speed: 300,
      mousewheel: {
        invert: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });

    window.scrollTo(0, 0);

    if(this.$route.query.event) {
      window.$('#eventPop').modal('show');
    }

    const timestamp = localStorage.getItem('modalCheck');
    const date = new Date();
    const dDay = new Date('2024-03-12 08:00:00');
    const endDate = new Date('2024-04-30 23:59:59');
    this.isIntegrationOver = date.getTime() >= dDay.getTime();
    if(date.getTime() - endDate.getTime() < 0) {
      if(!timestamp || (date.getTime() - Number(timestamp)) > 24*60*60*1000){
        if(!this.loading) {
          window.$('#announcePop').modal('show');
        }
      }
    }

    const youtubeRsp = await Api.get("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=6&playlistId=" + this.youtubeChannelId + "&key=" + this.youtubeApiKey);
    if(youtubeRsp.result) {
      var bucket = [];
      for(var i = 0; i<6; i++) {
        if(i%2 == 0) {
          bucket = [];
        }
        bucket.push(youtubeRsp.data.items[i].snippet.resourceId.videoId);
        if(i%2 == 1) {
          this.videoList.push(bucket);
        }
      }
      if(this.isMobileDevice) {
        this.videoSlot.push(this.videoList[0]);
      }
      else {
        this.videoSlot = this.videoList;
      }
    }
    else {
      alert(youtubeRsp.errormsg);
    }

    //this.fetchFaqListById([2, 1, 9, 26, 8, 14, 5, 28]);
    this.fetchFaqList(true);

    if(this.$route.query.mode == 'privacy') {
      window.$('#privacyPolicy').modal('show');
    }
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    async closeEvent() {
      if(!this.eventPhoneMidInput || !this.eventPhoneTailInput) {
        alert('휴대폰 번호를 정확히 입력하세요.');
        return;
      }

      // var returnVal = await Api.post('/api/v1/common/send/mms/event', {phone: this.eventPhoneHeadInput + this.eventPhoneMidInput + this.eventPhoneTailInput, eventCode: 1});
      // if(returnVal.result) {
      //   alert('쿠폰 ' + returnVal.data.numOfMms + '장이 정상적으로 지급되었습니다.\n휴대폰을 확인해주세요.');
      // }
      // else {
      //   alert(returnVal.errormsg);
      // }
    },
    closeModal() {
      if(this.oneDayCheck){
        var date = new Date();
        localStorage.setItem('modalCheck', date.getTime());
      }
      window.$('#announcePop').modal('hide');
    },
    moveNav(direction) {
        if(direction) {
            this.videoSlotIndex = (this.videoSlotIndex + 1) % 3;
            this.videoSlot = [];
            this.videoSlot.push(this.videoList[this.videoSlotIndex]);
        }
        else {
            if(this.videoSlotIndex == 0) {
                this.videoSlotIndex = 2;
            }
            else {
                this.videoSlotIndex--;
            }
            this.videoSlot = [];
            this.videoSlot.push(this.videoList[this.videoSlotIndex]);
        }
    },
    // async fetchFaqListById(ids) {
    //     console.log(ids);
    //     this.faqList = [];
    //     var rtnval = null;
    //     rtnval = await Api.post('https://buildmarket.kr/api/v1/faq/find/faq/list', {ids: ids});
    //     console.log(rtnval.data);
    //     console.log(rtnval.data.resultList);
    //     if(rtnval.result) {
    //         this.faqList = rtnval.data.resultList;
    //         [this.faqList[0], this.faqList[1]] = [this.faqList[1], this.faqList[0]];
    //         [this.faqList[2], this.faqList[6]] = [this.faqList[6], this.faqList[2]];
    //         [this.faqList[2], this.faqList[3]] = [this.faqList[3], this.faqList[2]];
    //         [this.faqList[2], this.faqList[4]] = [this.faqList[4], this.faqList[2]];
    //         // [1, 2, 8, 5, 26, 14, 9, 28]
    //     }
    //     else {
    //         alert(rtnval.errormsg)
    //     }
    // },
    async fetchFaqList(isHomeExpsred) {
        this.faqList = [];
        var rtnval = null;
        rtnval = await Api.post('https://buildmarket.kr/api/v1/faq/find/faq/list', {isHomeExpsred: isHomeExpsred});
        if(rtnval.result) {
            this.faqList = rtnval.data.resultList;
        }
        else {
            alert(rtnval.errormsg)
        }
    }
  }
}
</script>

<style scoped>
@media (max-width:1023.98px) {
  .plan-shortcut {
    width:180px;
    height:80px;

    .plan-shortcut-icon {
      left: 0;
      position: absolute;
    }
    .plan-shortcut-text {
      right: 10px;
      font-size: medium;
    }
  }
}

@media (min-width:1024px) {
  .plan-shortcut {
    width:200px;
    height:100px;

    .plan-shortcut-text {
      right: 15px;
      font-size: large;
    }
  }
}

.event-body {
    background-image: url('@/assets/img/event/230517_eventPopBg.jpg');
    background-size: 100%;
}

.bi4 {
    background-image: url('@/assets/img/portfolio/banners/MStudio_banner_rendered.jpg');
    background-position: 50% 75%;
}
.bi3 {
    background-image: url('@/assets/img/portfolio/banners/Mhouse_banner_rendered.png');
    background-position: 50% 10%;
}
.bi2 {
    background-image: url('@/assets/img/portfolio/banners/skyehouse_banner.png');
    background-position: 50% 50%;
}
.bi1 {
    background-image: url('@/assets/img/portfolio/banners/Moffice_banner_constructed.png');
    background-position: 50% 50%;
}
</style>