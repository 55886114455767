var tiu = `제1장 총칙<br>
<br>
제1조 (목적)<br>
<br>
본 약관은 주식회사 엠쓰리시스템즈(이하 “회사”)가 운영하는 온라인 사이트(이하 “서비스 홈페이지)를 통해 제공하는 제반 서비스를 이용함에 있어 “회사”와 “이용자”의 권리∙의무와 책임사항 등을 규정함을 목적으로 합니다.<br>
<br>
제2조 (용어의 정의)<br>
<br>
본 약관에서 사용하는 용어의 정의는 아래와 같습니다.<br>
1.	“이용자”: 엠쓰리시스템즈가 운영하는 “서비스 홈페이지”에 접속하여 본 약관에 따라 “회사”가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.<br>
2.	“회원”: 엠쓰리시스템즈와 이용계약을 체결하고 아이디(ID)를 부여받은 “이용자”로서 “회사”의 정보를 지속적으로 제공받으며 서비스를 이용할 수 있는 자를 말합니다. <br>
3.	“비회원”: “회원”이 아니면서 엠쓰리시스템즈가 제공하는 서비스를 이용하는 자를 말합니다.<br>
4.	“서비스”: “회사”가 “서비스 홈페이지”를 통해 제공하는 건축 플랫폼 및 소프트웨어의 이용과 이에 부수하는 용역을 의미합니다.<br>
5.	“아이디(ID”): “회원”의 식별과 서비스 이용을 위하여 “회원”이 가입 시 설정하고 “회사”가 승인하여 등록된 이메일 주소를 의미합니다.<br>
6.	“비밀번호(Password)”: “회원”이 등록한 “아이디”일치 여부 확인과 비밀보호를 위해 “회원” 스스로가 정하여 “서비스”에 등록한 문자와 숫자의 조합을 의미합니다.&nbsp;&nbsp;<br>
7.	“구성원”이라 함은 “회원”의 임직원으로서 “회원”으로 부터 서비스 이용 권한을 부여 받아 “회원”의 관리 하에 “서비스”를 이용하는 자를 의미하며, “회원”으로부터 부여 받는 권한에 따라 “관리자” 및 “일반 구성원”으로 구분됩니다.<br>
8.	“관리자”라 함은 “서비스”의 모든 관리 기능을 이용할 수 있고, “서비스” 이용계약을 해지할 수 있는 권한을 갖는 “구성원”을 의미합니다.<br>
9.	“도메인(domain)”이라 함은 “서비스 홈페이지”의 인터넷 주소로서 “서비스”가 제공되는 온라인 사이트 주소를 의미합니다.<br>
<br>
제3조 (약관의 명시 및 변경)<br>
<br>
1.	본 약관의 내용은 “회사”가 제공하는 개별 “서비스 홈페이지”를 통해 공지하고, 본 약관에 동의한 “이용자”에게 효력이 발생합니다.<br>
2.	“회사”는 본 약관의 내용을 “이용자”가 쉽게 알 수 있도록 “서비스 홈페이지”의 초기 화면에 게시합니다. 다만, 약관의 내용은 “이용자”가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br>
3.	“회사”는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br>
4.	“회사”가 본 약관을 개정할 경우에는 “이용자”가 알기 쉽도록 적용일자 및 개정사유를 명시하여 적용일자 7일 전부터 적용일자까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 최소한 30일 이상의 유예기간을 두고 공지하며 개정약관을 회원이 등록한 이메일로도 발송하여 사전에 알 수 있게 합니다.<br>
5.	변경된 약관은 공지된 시점부터 그 효력이 발생하며, 제4항에 따라 “회사”가 공지한 개정된 약관에 대해 “이용자”가 명시된 기간내에 거부의사 표시를 하지 않고 본 서비스를 계속 이용한다면 변경 후의 약관을 승인한 것으로 간주됩니다.<br>
6.	“이용자”가 개정약관의 적용에 동의하지 않는 경우 “이용자”는 이용계약을 해지할 수 있습니다. <br>
7.	개정약관의 적용에 대한 거부의사를 표시한 “이용자”는 원칙적으로 개정 전 약관이 우선 적용됩니다. 다만, 개정 전 약관을 적용할 수 없는 특별한 사정이 있는 경우 “회사”는 “이용자”와 이용계약을 해지할 수 있습니다.<br>
8.	본 약관에서 규정되지 않은 사항과 본 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.<br>
<br>
제2장 서비스 이용계약<br>
<br>
제4조 (서비스 이용요금)<br>
<br>
1.	“회사”가 제공하는 ”서비스”의 이용과 관련하여 “회원”이 납부하여야 할 이용요금은 홈페이지에 게시되거나 혹은 “회원”에게 별도로 안내한 사항을 따릅니다. <br>
2.	이용요금은 선불결제를 원칙으로 합니다. 단 '”회사”는 “회원”과 논의하여 결제 이전에 소정의 서비스를 제공할 수 있습니다.<br>
3.	이용요금 결제 수단은 신용카드를 기본으로 하며, 기타 결제 수단은 “회사”와 협의하여 결정합니다.<br>
4.	“회원”은 “서비스”에 대한 이용요금 결제와 관련하여 “회원”이 입력한 정보가 정확한지 확인합니다. 부정한 정보의 입력으로 발생한 책임 및 불이익은 전적으로 “회원”이 부담합니다.<br>
5.	“회사”는 홍보 및 마케팅 등의 목적으로 주위 시세에 따른 가격 할인, 이용기간 연장 등 혜택을 제공할 수 있습니다.<br>
6.	“유료서비스”의 이용요금에 관하여 “회사”와 “이용자” 간 별도 약정이 있을 경우, 1항에서 5항까지의 약관에도 불구하고 당해 약정을 우선 적용합니다.<br>
<br>
제5조 (서비스 이용계약)<br>
<br>
1.	“회사”와 서비스 홈페이지에 가입하고자 하는 “이용자”(이하 “가입요청자”)의 이용계약은 다음 각 호의 절차로 진행됩니다. <br>
① “가입요청자”는 본 약관에 동의합니다. <br>
② “가입요청자”는 “회사”에서 본인을 식별할 수 있도록 계정을 인증합니다.<br>
③ “가입요청자” 회원등록에 필요한 필수사항을 입력합니다.<br>
④ “가입요청자”가 회원등록을 완료하면 “회사”에서 승인합니다.<br>
2.	“회사”는 “가입요청자”의 신청에 대하여 승인함을 원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 않거나, 혹은 승인한 이후 다음 각 호의 사유를 확인하는 경우 계약을 해지할 수 있습니다.<br>
①”가입요청자”가 이 약관에 의하여 이전에 이용 자격을 상실한 적이 있는 경우(단 “회사”로부터 재가입 승인을 얻은 경우에는 예외로 함)<br>
② 실명이 아니거나 타인의 명의를 도용한 경우<br>
③ “가입요청자”가 허위의 정보를 제공하거나 “회사”가 요청하는 정보를 기입하지 않은 경우<br>
④ 범죄 및 부정한 용도로 “서비스”를 이용하고자 하는 경우<br>
⑤ “가입요청자”가 만 14세 미만인 경우<br>
⑥ 본 약관 관련 규정을 위반하여 신청하는 경우<br>
3.	“회사”는 보다 나은 “서비스”를 제공하기 위하여 설비 및 기술상 또는 업무상 보완을 진행할 경우 신청 승인을 보류할 수 있습니다.<br>
4.	회원가입신청을 승인하지 아니하거나 보류한 경우, “회사”는 그와 같은 사실을 “가입요청자”에게 통지하는 것을 원칙으로 합니다.<br>
<br>
제6조 (청약철회, 계약해지 등)<br>
<br>
1.	“회사”와 제5항에 따른 이용계약을 체결한 “회원”은 결제일로부터 7일 이내에 “회사”에 청약의 철회를 요청할 수 있습니다.<br>
2.	1항에도 불구하고 “회원”은 제공되는 “서비스”의 내용이 표시·광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 “서비스”를 공급받은 날로부터 3개월 이내 또는 그 사실을 안 날 및 알 수 있었던 날부터 30일 이내에 청약을 철회할 수 있습니다.<br>
3.	1항 또는 2항의 청약의 철회는 “회원”이 전화, 전자우편 또는 모사전송으로 “회사”에 “회원”의 의사를 피력하여 회사에 접수될 때 효력이 발생합니다.<br>
4.	“회사”는 “회원”의 의사표시를 접수한 후 지체 없이 그 처리결과를 “회원”에게 회신합니다.<br>
5.	“회원”은 제1항 또는 제2항의 기간이 경과한 이후 “서비스” 이용계약의 해지를 “회사”에 신청할 수 있으며, “회사”는 관련 법령 및 본 약관에서 정하는 바에 따라 이를 처리합니다.<br>
6.	제5항에 따라 “서비스” 이용 계약이 중도 해지되는 경우, 할인 요금제를 이용한 “회원”은 서비스 개시시일부터 해지일까지 할인 받은 금액의 총합을 “회사”에 반환합니다.<br>
7.	“회사”는 다음 각호의 경우에 “회원”에게 이용약관 위반 사실 및 해지 사실을 사전 통지하고 “회원”과의 이용계약을 해지할 수 있습니다.<br>
①	“회원”이 “회사”나 다른 “이용자” 등 타인의 권리나 명예, 정당한 이익을 침해하는 행위를 한 경우<br>
②	 “회원”이 회생절차 또는 파산을 신청한 경우<br>
③	“회원”이 “서비스”를 불법적인 용도로 사용하였거나, 불법 정보를 유통한 경우<br>
④	“회원”이 직접 용역거래 등 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br>
⑤	“회원”이 본 약관을 위반하고 “회사”의 시정요청을 받은 후 7일 이내에 위반사항을 시정하지 않은 경우<br>
⑥	“회원”의 이용금액의 연체로 인하여 서비스의 이용이 정지된 후 30일 내에 연체된 이용요금을 전액 지급하지 않는 경우<br>
8.	이용계약이 해지되는 경우, “회원”이 “회사”에 제공한 정보는 관련 법령 및 “회사”의 개인정보 처리방침에서 정하는 사항을 제외하고 삭제합니다. “회원”이 위와 같은 사항에 유의하여 이용계약 해지 전에 필요한 데이터의 백업 등의 조치를 취하여야 합니다.<br>
9.	이용계약이 종료된 경우, 회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다.<br>
<br>
제3장 서비스 이용<br>
<br>
제7조 (서비스의 제공 및 변경)<br>
<br>
1.	“회사”는 다음과 같은 서비스를 제공합니다.<br>
①	기획 설계 서비스<br>
②	“회원”이 신규 프로젝트 (설계 및 공사)를 수주할 수 있도록 온라인 플랫폼을 제공하는 서비스<br>
③	“회원”의 설계 및 공사 관리 업무를 위하여 제공하는 PC 혹은 웹용 소프트웨어를 이용할 수 있도록 지원하는 서비스<br>
④	기타 “이용자”를 위하여 제공하는 서비스<br>
⑤	기타 위 사항에 부수하는 소프트웨어, 콘텐츠 및 용역<br>
2.	“회사”는 “회원”의 원활한 서비스 이용 및 업무상 지식 정보 제공을 목적으로 하는 정보를 이메일 및 서비스 홈페이지 등을 통하여 제공할 수 있습니다.<br>
3.	“회사”는 “이용자”가 선택한 “서비스”의 종류에 따라 이용할 수 있는 범위 및 수준을 제한하여 “서비스”를 제공합니다.<br>
4.	“회사”가 제공하는 서비스의 내용을 기술적 사양의 변경 등의 이유로 변경할 경우에는 그 사유를 “회원”에게 통지하거나, “이용자”가 알아볼 수 있도록 공지사항으로 게시합니다.<br>
5.	“회사”는 정보통신인프라의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합당한 이유가 있는 경우 '서비스'의 제공을 일시적으로 중단할 수 있습니다.<br>
6.	“회사”는 “서비스”의 원활한 제공을 위하여 정기적 또는 비정기적 점검을 실시할 수 있으며, 점검기간은 “서비스 홈페이지”에 공지한 바에 따릅니다.<br>
7.	“회사”는 “서비스”의 원활한 제공을 위하여 “서비스”의 이용방법, 이용시간, 이용금액 등의 내용(이하 “서비스 내용”) 전부 또는 일부를 변경할 수 있습니다.<br>
8.	“서비스 내용”에 대하여 변경이 있는 경우, 변경 전에 변경사유, 변경될 내용 및 제공 일자 등을 “서비스 홈페이지”에 게시해야 합니다.<br>
<br>
제9조 (서비스 이용제한)<br>
<br>
1.	“회사”는 “이용자”가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 경고 일시정지, 영구 이용정지 등으로 단계적으로 서비스 이용을 제한할 수 있습니다.<br>
2.	“회사”는 전항에도 불구하고 다음 각 호에 해당하는 경우 즉시 영구 이용정지를 할 수 있습니다. 본 항에 따른 영구 이용정지시 “회사”는 “서비스” 및 이에 부수한 용역, 혜택 등을 일절 제공하지 않으며, 이와 관련하여 별도로 보상하지 않습니다.<br>
①	가입 신청 시 허위 내용을 등록한 경우<br>
②	타인의 “서비스” 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br>
③	타인의 명의 및 결제를 도용하는 경우<br>
④	저작권법을 위반한 불법프로그램을 제공하는 경우<br>
⑤	[정보통신망 이용촉진 및 정보보호 등에 관한 법률]을 위반한 불법통신 및 해킹을 시도한 경우<br>
⑥	 “회사”의 지적재산권 서비스를 침해하는 행위 <br>
⑦	악성프로그램의 배포한 경우<br>
⑧	기타 관련 법령을 위반하거나 부당한 행위를 한 경우<br>
3.	전항에 따른 이용정지 시 서비스를 통해 획득한 포인트 등 및 기타 혜택 등도 모두 소멸되며, “회사”는 이에 대해 별도로 보상하지 않습니다.<br>
4.	“회사”는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용이 본 약관과 다른 이용제한 정책 및 개별 서비스 상의 운영정책을 규정하고 있는 경우 각 개별 약관의 규정에 따릅니다.<br>
5.	“회원”이 이용대금을 연체하거나 결제 수단 등록 및 갱신에 실패하여 이용대금 지불이 최종 실패한 경우 “회사”는 다음 절차와 방법에 따라 “회원”의 “서비스” 이용을 정지할 수 있습니다.<br>
①	 “회사”는 “서비스”를 이용하는 “회원”이 이용대금을 30일 이상 연체하거나 결제 수단 등록 및 갱신에 실패하는 경우 “서비스”의 제공을 정지할 수 있습니다.<br>
②	“회사”가 “회원”의 “서비스” 이용을 정지할 경우 이용요금 연체 사실과 연체된 금액, “서비스”의 이용정지 개시일을 본 약관에서 정하는 방법에 따라 사전에 “회원”에게 통지합니다.<br>
③	“회원”은 위 통지를 받은 후 30일 이내에 연체된 이용요금 전액을 납부하고, 그 사실을 “회사”에 통지하는 경우 “회사”는 “회원”의 “서비스” 이용 정지상태를 해제할 수 있습니다.<br>
6.	“회원”이 제4항에서 규정한 기간 내에 연체된 이용요금 전액을 납부하지 않는 경우 “회사”는 제6조 7항 6호에 따라 연체 사실과 계약 해지 사실, 관련 데이터 삭제 사실 등을 “회원”에게 통지하고 이용계약을 해지할 수 있습니다.<br>
<br>
제4장 정보보호<br>
<br>
제10조 (개인정보 보호)<br>
<br>
1.	“회사”는 “이용자”의 회원가입 시 서비스 제공에 필요한 최소한의 범위에서 각 호와 같은 개인정보를 수집합니다.<br>
①	 이메일 주소<br>
②	 비밀번호<br>
③	 ID<br>
④	 성별<br>
⑤	 연령대<br>
2.	“회사”가 “이용자”의 개인식별이 가능한 개인정보를 수집할 때에는 반드시 당해 “이용자”의 동의를 받습니다.<br>
3.	“이용자”가 “회사”에 제공한 개인정보는 목적 이외 용도로 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음 각 호의 경우에는 예외로 합니다.<br>
①	 서비스 통계 작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
②	수사기관으로부터 적법한 법적 절차에 따라 정보제공을 요청받은 경우<br>
③	기타 법률에 의해 요구되는 경우<br>
4.	“회사”가 제2항과 제3항에 의해 “이용자”의 동의를 받아야 하는 경우 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받은 자, 제공목적 및 제공할 정보의 내용)등 개인정보보 보호법 제 15조 제2항 및 제17조 제2항에 규정한 사항을 미리 명시하거나 고지해야 하며 “이용자”는 언제든지 이 동의를 철회할 수 있습니다.<br>
5.	“회사”는 [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 기타 관련 법령과 “회사”의 개인정보 처리방침에 따라 “회사”에 제공한 “이용자”의 개인정보 수집 및 이용, 관리 등 처리합니다.<br>
6.	“회사”는 “이용자”가 안전하게 “서비스”를 이용할 수 있도록 개인정보 보호를 위한 보안시스템을 갖추어야 하며, 개인정보 처리방침을 공시하고 준수하여야 합니다. 다만, “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보 처리방침이 적용되지 않습니다.<br>
7.	“이용자”는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취해야 합니다. “이용자”가 오류의 정정을 요구한 경우 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br>
<br>
제11조 (정보의 변경 및 활용)<br>
<br>
1.	“회원”은 개인정보관리 화면을 통하여 언제든지 본인의 정보를 열람하고 수정할 수 있습니다. 다만, “회사”의 “서비스” 관리를 위하여 필요한 “회원”의 '실명', '아이디', '도메인' 등 “회사”가 “이용자”의 임의 수정을 불허하는 항목을 수정하기 위해서는 “회사” 허가를 받아야 합니다.<br>
2.	“회원”의 정보가 변경되었을 경우 “회원”은 서비스 홈페이지에서 직접 수정을 하거나 전자우편 및 기타 방법으로 “회사”에 변경사항을 알려야 합니다.<br>
3.	“회원”은 본인의 정보에 대한 변경사항을 적절히 수정하지 않아 발생한 불이익에 대하여 “회사”는 책임을 부담하지 않습니다.<br>
4.	“회원”의 이름 혹은 로고 등은 “회사”의 홈페이지 혹은 홍보 및 마케팅 자료에 노출될 수 있으며, 이름 혹은 로고의 노출이 불가한 경우 전화, 이메일, 채팅 등의 방법으로 “회사”에 통보해 주시기 바랍니다.<br>
<br>
제12조 (ID 및 비밀번호와 회원 정보의 관리)<br>
<br>
1.	“회원”의 ID와 비밀번호에 대한 관리책임은 원칙적으로 “회원”에게 있습니다.<br>
2.	“회원” ID와 비밀번호의 타인 이용을 금지합니다.<br>
3.	“회사”는 아래 각 호에 해당하는 경우 “회원”의 ID 또는 비밀번호 사용을 제한할 수 있습니다.<br>
①	 개인정보가 유출될 우려가 있거나 반사회적 또는 미풍양속에 어긋나는 경우<br>
②	“회사” 및 “회사”의 운영자로 오인될 우려가 있는 경우<br>
③	기타 사용 불허에 합리적 사유가 있는 경우<br>
8.	“회원”은 ID 또는 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.<br>
9.	전항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지 후 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임을 부담하지 않습니다.<br>
10.	“회원”은 회원 정보가 변경된 경우, 이에 대한 업데이트를 할 의무가 있습니다. 주요 정보가 변경되었음에도 해당 사항을 수정하지 않음으로써 발생한 각종 손해와 잘못된 수정으로 인하여 발생한 손해는 “회원”이 부담하여야 하며, “회사”는 이에 대하여 아무런 책임을 지지 않습니다.<br>
<br>
제13조 (데이터의 보존 및 파기)<br>
<br>
1.	“이용자”는 서비스 이용 중 데이터를 별도로 상시 백업, 저장하여야 하며, “회사”가 “이용자”의 데이터를 백업하거나 “이용자”에게 제공할 의무는 없습니다. “이용자”가 전단의 의무를 소홀히 하여 발생한 데이터의 유출, 누락이나 손실에 대해서는 “회사”는 책임을 부담하지 않습니다.<br>
2.	서비스 이용기간 만료, 서비스 이용계약의 해제 또는 해지 등의 사유로 “회사”가 서비스 제공을 종료한 경우 “회사”는 “이용자”의 정보를 비롯한 모든 데이터를 즉시 파기합니다. 단, 다음 각 호의 정보들은 관련 법령에 따라 개인정보를 일정 기간 동안 보존하며, 해당 개인정보 또는 개인정보 파일을 다른 개인정보와 분리하여서 저장, 관리하고 보존기간 경과 후 파기합니다.<br>
①	서비스 신청 시 수집한 정보주체(최고관리자)의 일부 개인정보<br>
②	계약 또는 청약철회 등에 관한 기록<br>
③	소비자의 불만 또는 분쟁처리에 관한 기록<br>
<br>
제5장 의무 및 책임<br>
<br>
제14조 (회사의 의무)<br>
<br>
1.	“회사”는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.<br>
2.	“회사”는 “서비스” 이용과 관련하여 발생하는 “이용자”의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비하여야 합니다.<br>
3.	“회사”가 “이용자”에 대한 “서비스”의 제공이 불가능해지는 경우, “회사”는 부득이한 사유가 없는 한 “서비스”의 제공 중단 예정일의 3개월 전까지 본 약관 제19조에서 정한 방법으로 “이용자”에게 통지하여야 합니다.<br>
<br>
제15조 (이용자의 의무)<br>
<br>
1.	“이용자”은 다음 각 호의 행위를 하여서는 아니 됩니다.<br>
①	가입 및 이용 시 허위정보를 등록하거나 변경하는 행위<br>
②	제3자의 정보나 명의를 도용하거나 부정하게 사용하는 행위<br>
③	제3자의 개인정보 및 타인에게 귀속된 정보를 그의 동의 없이 수집, 저장, 공개하는 행위<br>
④	“회사”가 게시한 정보를 변경하거나 제3자에게 제공하는 행위<br>
⑤	제3자의 코드, 파일, 프로그램을 포함하고 있는 자료를 무단으로 게시하거나 메일로 발송하는 행위 기타 “회사” 또는 제3자의 지식재산권을 침해하는 행위<br>
⑥	“회사” 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
⑦	외설, 폭력적, 인신공격적, 비방적인 내용, 기타 풍기문란을 조장하거나 미풍양속에 반하는 내용 (어린이에게 유해하거나 제3자의 사생활을 침해하는 내용을 포함합니다.) 또는 수신자의 동의 없이 발송하는 광고성 내용 (정크메일, 스팸메일, 행운의 편지, 피라미드 조직에 가입할 것을 권유하는 내용을 포함합니다.)의 메시지, 팩스, 메일, 정보를 공개 또는 게시하거나 제3자에게 전송하는 행위<br>
⑧	“회사”의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 “서비스”를 이용하는 행위<br>
⑨	컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 악성코드 등 관련 법령에 의하여 전송 또는 게시가 금지되는 정보를 이용 또는 전송하는 행위<br>
⑩	해킹, 비밀번호 마이닝 (password mining) 등을 비롯한 수단으로 부당하게 “회사”의 서비스에 접근을 시도하는 행위<br>
⑪	“회사”의 사전 서면 동의 없이 “회사”의 설비 및 소프트웨어, 시스템을 무단으로 변경하거나, 변경된 버전의 소프트웨어를 사용하는 행위<br>
⑫	“회사”의 사전 서면 동의 없이 로봇, 스파이더, 스크래퍼 등을 비롯한 자동화 수단을 사용하여 “회사”의 설비 및 소프트웨어, 시스템 등에 접근을 시도하는 행위; 혹은 유사 수단을 활용하여 “회사”가 외부 접근을 차단하거나 예방하기 위한 목적으로 마련한 설정들을 우회하는 행위<br>
⑬	이용 계약 도중 또는 종료 후에 “회사”의 “서비스”를 무단으로 수정, 보완, 해석하거나, 이를 바탕으로 파생된 신규 서비스를 직접 구현하거나 제 3자를 통해 우회 구현/판매하는 행위<br>
⑭	“회사”의 명시적인 서면 동의 없이 서비스를 역설계(reverse-engineer), 역컴파일링(decompile), 혹은 역분해(disassemble) 하는 행위; 혹은 이를 통하여 “서비스”의 소스코드(source code)를 추적하기 위한 시도를 하는 행위<br>
⑮	기타 불법적이거나 부당한 행위<br>
2.	“이용자”은 관련 법령 및 본 약관, “회사”의 운영정책 및 이용안내, “회사”가 “서비스”와 관련하여 공지한 주의사항 및 통지사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.<br>
3.	“이용자”는 “서비스”를 신청하기 전에 반드시 “회사”가 전자우편 혹은 서비스 홈페이지를 통해 제공하는 상품의 거래 및 이용조건을 확인하여야 합니다. “회사”의 귀책사유 없이 “이용자”가 거래 및 이용조건을 확인하지 아니하고 “서비스”를 신청하여 발생한 모든 손실, 손해에 대한 책임은 “이용자”가 부담합니다.<br>
<br>
제16조 (손해배상)<br>
<br>
1.	“회사”는 “회원”이 서비스를 이용함에 있어 “회사”의 고의 또는 과실로 인해 손해가 발생한 경우 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.<br>
2.	“이용자”가 본 약관을 위반하거나 관계 법령을 위반하여 “회사”에 손해가 발생한 경우에는 “회사”에 그 손해를 배상합니다.<br>
<br>
제18조 (책임제한)<br>
<br>
1.	“회사”는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 “이용자” 또는 제3자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.<br>
①	천재 지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br>
②	“이용자”의 시스템 환경이나 통신사 또는 호스팅 업체의 장애 등 회사의 관리 범위 밖의 사정으로 인한 경우<br>
③	현재의 보안기술 수준으로는 방어가 곤란한 네트워크 해킹 등으로 인한 경우<br>
④	서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우<br>
⑤	“이용자”의 귀책사유로 메일 또는 데이터가 유출되거나 서비스 이용에 장애가 있는 경우<br>
⑥	“회사”의 고의 및 과실이 없는 경우<br>
4.	“회사”는 “회사”와 “이용자”간 별도의 용역 계약이 있는 경우를 제외하고, “이용자” 상호간의 용역 거래를 위한 서비스를 제공할 뿐이므로 “회사”는 용역이나 물품을 판매하거나 구매하고자 하는 “이용자”을 대리하지 않으며, 서비스를 통하여 “이용자”에게 제공된 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 대해서는 책임을 지지 않습니다. 이로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는 손익 등 부가적, 영업적 손해에 대하여 책임을 면합니다.<br>
5.	“회사”는 “이용자”가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다. 이와 관련한 일체의 위험과 책임은 해당 “이용자”가 부담해야 하며 이로 인한 분쟁의 해결은 거래당사자들이 직접 수행하여야 합니다. “회사”는 이에 대하여 관여하지 않으며 어떠한 책임도 부담하지 않습니다.<br>
6.	“회사”는 “이용자” 상호 간 또는 “이용자”와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우 이에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다. 특히, 견적조건등록정보의 오류, 미비, 설계의 품질, 건축물의 품질, 대금지급, 하자보수 등으로 인하여 “이용자”가 입는 손해에 대해서는 책임(제조물 책임 포함)을 지지 않습니다.<br>
7.	“회사”는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br>
8.	“회사”는 “이용자”이 서비스 이용 종료 후 제13조에 따라 파기된 데이터와 “이용자”가 직접 삭제를 요청한 데이터 등 백업의무를 소홀히 하여 발생한 데이터의 손실에 대해서는 책임을 지지 않습니다.<br>
<br>
제19조 (지적재산권의 귀속 등)<br>
<br>
1.	“서비스”에 대한 저작권 등 지적재산권은 “회사”에 귀속됩니다. 다만, “이용자”가 “서비스” 이용 중 게시하거나 등록한 데이터의 지식재산권은 “이용자”에게 귀속됩니다.<br>
2.	“서비스” 이용계약자의 지위 및 이용권한에 대한 타인 양도 및 판매, 담보제공 등의 처분을 금지합니다.<br>
3.	“이용자”는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 게재된 데이터를 상업적으로 이용할 수 없으며 이를 위반하여 발생하는 제반 문제에 대한 책임은 “이용자”에게 있습니다.<br>
<br>
제20조 (콘텐츠의 제작 및 사용)<br>
<br>
1.	“회원”은 “회사”에게 다음 각 호의 사항을 허락한 것으로 간주합니다. <br>
①	건축 과정, 건축 현장, 완공 현장에 대한 사진 및 동영상 등 콘텐츠의 제작<br>
②	제작한 콘텐츠를 검색 결과로 사용<br>
③	제작한 콘텐츠를 광고로 사용<br>
2.	“회사”가 “회원”에게 저작권이 귀속된 건축저작물을 복제한 사진이나 동영상을 이용하여 콘텐츠를 제작한 경우, “회원”은 다음 각 호의 범위 내에서 “회사”가 해당 콘텐츠를 사용, 수정, 복제, 전송, 배포하는 행위를 허락합니다. <br>
①	서비스 내에서 콘텐츠를 사용하거나, 서비스를 홍보하기 위한 목적으로 인터넷, 모바일, SNS를 포함한 디지털 마케팅 채널 및 통신사 등에게 콘텐츠의 내용을 보도, 방영하는 경우<br>
②	콘텐츠를 복제ᆞ전송ᆞ전시하는 방법으로 제휴서비스에서 콘텐츠를 제공하는 경우<br>
3.	“회사”가 제작한 콘텐츠에 대한 저작권을 포함한 지식재산권은 “회사”가 보유합니다.<br>
4.	“회원”이 “회사”에게 제공한 건축과정, 건축현장, 완공현장에 대한 사진 등을 제2항 각호의 범위에 한하여 제한 없이 사용할 수 있습니다.<br>
<br>
제21조 (이용자에 대한 통지)<br>
<br>
1.	“회사”는 “회원”이 사전 등록한 전자우편 주소로 전자우편을 발송하거나, “서비스” 화면상 안내 등으로 “이용자”에게 통지할 수 있습니다.<br>
2.	“회사”는 불특정다수 “이용자”에 대한 통지의 경우 “서비스 홈페이지”에 공지사항을 게시함으로써 개별 통지를 갈음할 수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.<br>
<br>
제22조 (재판관할)<br>
<br>
1.	본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다.<br>
2.	“회사”와 “이용자”간 발생한 분쟁의 관할법원은 민사소송법상 관할법원으로 합니다.<br>
3.	해외에 주소나 거소가 있는 “이용자”의 경우 “회사”와 “이용자”간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.<br>
<br>
부칙<br>
<br>
제 1 조 약관의 효력<br>
본 약관은 2022년 0월 00일부터 시행합니다.<br>`

export default tiu;